import React, { useState, useEffect } from 'react';
import ReceiptIcon from '../images/receipt.svg';
import Ticket from './Ticket';
import { Modal } from 'antd';
import moment from 'moment';
import { lpad } from '../single-page/utils/index.js';

export default function SmallWinner({ winner }) {
  const [modalOpen, setModalOpen] = useState(false);

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  return (
    <div className="rounded_square" style={{padding: '0 10px', margin: '5px 0', borderColor: '#253875', position: 'relative', cursor: 'pointer'}} onClick={toggleModal}>
      <img src={ReceiptIcon} alt="Recibo" width="35" style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}} />
      <p>
        <b>{winner.name}</b><br/>
        Telefone: {winner.phone}<br/>
        Título: {lpad(winner.number, winner.ticket.campaign.digits)}
      </p>
      <Modal title="Detalhes" visible={modalOpen} centered onCancel={toggleModal} footer={null}>
        <div>
          <p><b>Nome do comprador:</b> {winner.name}</p>
          {
            winner.affiliate_name &&
              <p><b>Revendedor:</b> {winner.affiliate_name}</p>
          }
          <p><b>Telefone:</b> {winner.phone}</p>
          <p><b>CPF:</b> {winner.cpf}</p>
          <p><b>{winner.city}{winner.state && '/'}{winner.state}</b></p>
          <p><b>Hora da compra:</b> {moment(winner.purchase_order_created_at).format('DD/MM/YYYY HH:mm')}</p>
          <p>
            <Ticket ticket={winner.ticket} showCampaign={false} showTime={false} />
          </p>
        </div>
      </Modal>
    </div>
  );
}


