import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Input, Button, Form, Layout } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { UserContext } from '../App.js';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';

const { Content } = Layout;

export default function SignIn({isMobile, project}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [form] = Form.useForm();
  const [resetForm] = Form.useForm();
  const history = useHistory();
  const [sentOnce, setSentOnce] = useState(false);
  const otpRef = useRef();
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true);

  function request_token(formData) {
    document.activeElement.blur();
    setSendButtonEnabled(false);
    setLoading(true);
    axios.post('/customers/password', { customer: formData })
      .then(function(response){
        setSentOnce(true);
        toast.success(`Código enviado para o número ${response.data.phone}`);
        setTimeout(() => {
          setSendButtonEnabled(true);
        }, 60000);
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
      })
      .then(function() {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (sentOnce) {
      if (otpRef.current.nativeElement.children.length === 0) return;

      for(let inputIndex in otpRef.current.nativeElement.children) {
        let input = otpRef.current.nativeElement.children[inputIndex];
        if (!input.attributes) continue;
        input.inputMode = 'tel';
        input.pattern = '[0-9]*';
        input.type = 'tel';
      }
      otpRef.current.nativeElement.children[0].focus();
    }
  }, [sentOnce]);

  // TODO: preciso impedir o usuário de pedir outro token em menos de 2 minutos.
  // TODO: preciso levar em conta o CPF do cara nessa requisição, porque nosso token SMS é fraco.

  function password_reset(formData) {
    document.activeElement.blur();
    setLoading(true);
    axios.put('/customers/password', { customer: formData })
      .then(function(){
        window.location.href = `/${project.name}/site?flash_notice=Senha atualizada`
      })
      .catch(function(error){
        setErrors(error.response.data.errors);
        toast.error('Erro ao processar solicitação.');
      })
      .then(function() {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!user || !user.cpf) {
      history.push(`/${project.name}/site/cpf`);
    } else {
      form.setFieldsValue({ cpf: user.cpf, name: user.name });
      resetForm.setFieldsValue({ cpf: user.cpf });
    }
  }, []);

  return (
    <>
      <PurchaseOrderSteps step={1} isMobile={isMobile} />
      <Content className="smallest_container">
        <p className="center">
          Boas vindas à Nacional Cap!
        </p>
        <h1>
          Redefinição de senha
        </h1>
        <Form form={form} layout="vertical" name="request_token" className="login-form" initialValues={{ remember: true }} onFinish={request_token}>
          <Form.Item name="cpf" rules={[{ required: true }]} label="CPF" required>
            <MaskedInput form={form} name="cpf" disabled={true} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
          </Form.Item>
          <Form.Item>
            <Button disabled={!sendButtonEnabled} shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
              {!sentOnce ? 'Enviar' : 'Reenviar'} código
            </Button>
          </Form.Item>
        </Form>
        {
          sentOnce &&
          <Form layout="vertical" form={resetForm} name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={password_reset}>
            <Form.Item name="cpf">
              <Input type="hidden" />
            </Form.Item>
            <p className="center">Por favor, digite o código de 6 dígitos que foi enviado para o seu número, junto com sua nova senha, para prosseguirmos</p>
            <Form.Item name="reset_password_token" rules={[{ required: true }]} required label="Código" hasFeedback={!!errors['reset_password_token']} validateStatus={errors['reset_password_token'] && 'error'} help={errors['reset_password_token']?.join(', ')}>
              <Input.OTP ref={otpRef} size="large" length={6} />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true }]} required label="Nova Senha" hasFeedback={!!errors['password']} validateStatus={errors['password'] && 'error'} help={errors['password']?.join(', ')}>
              <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
            </Form.Item>
            <Form.Item name="password_confirmation" rules={[{ required: true }]} required label="Confirme sua senha" hasFeedback={!!errors['password_confirmation']} validateStatus={errors['password_confirmation'] && 'error'} help={errors['password_confirmation']?.join(', ')}>
              <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
            </Form.Item>
            <Form.Item>
              <Button shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
                Redefinir senha
              </Button>
            </Form.Item>
          </Form>
        }
      </Content>
    </>
  );
}