import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify'
import { UserOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Button, Form, Layout, Steps } from 'antd';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { UserContext } from '../App.js';
import Swal from 'sweetalert2';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';


const { Content } = Layout;

export default function Cpf({isMobile, project}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const disabled = loading;
  const { user, setUser } = useContext(UserContext);
  const [ form ] = Form.useForm()
  const history = useHistory();
  const acesso_url = window.location.pathname.startsWith('/site/acesso');
  
  function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }

  function cpf_check(formData) {
    document.activeElement.blur();
    setLoading(true);
    axios.post('/customers/cpf/check', { cpf: formData.cpf })
      .then(function(response){
        if (response.data.exists) {
          handleExistingAccount(response.data);
        } else {
          setUser(response.data);
          history.push(`/${project.name}/site${acesso_url ? '/acesso' : ''}/cadastro`);
        }
      })
      .catch(function(error) {
        if (error.response.status == 422){
          setErrors({cpf: [error.response.data.error]});
        } else {
          toast.error('Erro ao processar solicitação.');
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  function handleExistingAccount(data) {
    Swal.fire({
      title: 'Confirme seus dados para continuar',
      html: `<p style="text-align: left"><b>Nome:</b> ${data.masked_name}<br/><b>Telefone:</b> ${data.masked_phone}<br/><b>E-mail:</b> ${data.masked_email}</p>`,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Prosseguir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        setUser(data);
        signIn(data.cpf);
      }
    });
  }

  function signIn(cpf) {
    axios.post('/customers/sign_in', { customer: { cpf: cpf, password: '********' } })
      .then(function(){
        if (findGetParameter('history'))
          window.location.href = `/${project.name}/site/minha-conta`
        else
          window.location.href = `/${project.name}/site`
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
      })
      .then(function() {
        setLoading(false);
      });
  }

  return (
    <>
      <PurchaseOrderSteps step={1} isMobile={isMobile} />
      <Content className="smallest_container">
        <p className="center">
          Boas vindas à Nacional Cap!
        </p>
        <h1 style={{fontSize: '150%'}}>
          Precisamos identificá-lo para prosseguirmos.
        </h1>
        <Form form={form} layout="vertical" name="cpf_check" className="login-form" initialValues={{ remember: true }} onFinish={cpf_check}>
          <Form.Item name="cpf" label="CPF" required hasFeedback={!!errors['cpf']} validateStatus={errors['cpf'] && 'error'} help={errors['cpf']?.join(', ')}>
            <MaskedInput name="cpf" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
          </Form.Item>
          <Form.Item>
            <Button disabled={disabled} shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
              Continuar
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
}