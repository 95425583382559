import React, { useState, useEffect } from 'react';
import ClockIcon from '../../../images/clock.svg';
import moment from 'moment';
import { Row, Col, Button, QRCode } from 'antd';
import { copyToClipboard, numberAsCurrency } from '../../utils';
import { toast } from 'react-toastify';
import DotLogo from '../../../images/dot-logo.png';
import PixIcon from '../../../images/pix.png';


export default function OrderQrCode({order, isMobile}) {
  if (order?.status !== 'waiting_payment' || moment(order?.qr_code_received_at).isBefore(moment().subtract(15, 'minutes')))
    return null;

  const [qrCode, setQrCode] = useState(!isMobile);
  const [timeLeft, setTimeLeft] = useState(minutesLeft(order));

  function minutesLeft(order) {
    const now = moment();
    const expires_at = moment(order.qr_code_received_at).add(15, 'minutes');
    if (expires_at.isBefore(now)) return '00:00';
    return moment.utc(moment.duration(expires_at.diff(now)).asMilliseconds()).format('mm:ss');
  }

  useEffect(() => {
    if (order) {
      const interval = setInterval((order) => {
        var tl = minutesLeft(order);
        if (tl === '00:00') {
          window.location.reload();
        }
        setTimeLeft(tl);
      }, 1000, order);
      return () => clearInterval(interval);
    }
  }, [order]);

  function clipboardQRCode() {
    try {
      copyToClipboard(order.qr_code_text);
      toast.success('Código copiado');
    } catch (err) {
      toast.error('Erro ao copiar código. Selecione o código acima do botão e copie manualmente.');
    }
  }

  function showQrCode() {
    setQrCode(true);
  }

  return (
    <div id="order_qr_code">
      {/* <h1 className="left">Finalize sua transação</h1> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{backgroundColor: '#253875', marginLeft: -15, marginRight: -15, padding: '15px 15px 100px 15px'}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3 style={{textAlign: 'center', color: 'white'}}>
            <img src={PixIcon} style={{verticalAlign: 'middle'}}/>
            &nbsp;&nbsp;Pagar com PIX
          </h3>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="rounded_square" style={{backgroundColor: 'white', color: '#253875', paddingTop: 0, paddingRight: 0, paddingLeft: 0, overflow: 'hidden'}}>
          <p style={{margin: 0, backgroundColor: '#F6E93C', color: '#253875', padding: 10, textAlign: 'center'}}>
            Seu código expira em:&nbsp;&nbsp;
            <img src={ClockIcon} style={{width: '30px', height: '30px', verticalAlign: 'middle'}} />
            <span style={{verticalAlign: 'center', fontSize: 20, minWidth: '65px', display: 'inline-block', verticalAlign: 'middle'}}>{timeLeft}</span>
          </p>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{padding: '10px 10px 0 10px'}}>
                <p style={{textAlign: 'center'}}>
                  Quantidade de títulos: <b>{order?.tickets_amount}</b>
                </p>
                <h2 style={{textAlign: 'center'}}>
                  {numberAsCurrency(order?.total_value)}
                </h2>
                <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                  Você está quase lá!<br/>
                  Essa é a sua chance de mudar de vida
                </p>
              </div>
              <p style={{textAlign: 'center'}}>
                <Button type="primary" size="medium" shape="round" onClick={clipboardQRCode} style={{fontWeight: 'bold'}}>
                  Copiar código pix
                </Button>
              </p>
              <p className="purple" style={{textAlign: 'center', fontSize: '80%'}}>Como pagar com o código</p>
              <p style={{padding: '0 20px', fontSize: '80%'}}>
                1 - Clique no botão acima para copiar o código PIX.<br/>
                2 - Abra o seu aplicativo bancário<br/>
                3 - Acesse a área PIX - Pix copia e cola<br/>
                4 - Siga as instruções do aplicativo
              </p>
              {
                !qrCode &&
                  <p style={{textAlign: 'center'}}>
                    <Button type="secondary" size="medium" shape="round" onClick={showQrCode} style={{border: '1px solid #253875', color: '#253875', fontWeight: 'bold'}}>
                      Ver QR Code
                    </Button>
                  </p>
              }
            </Col>
            {
              qrCode &&
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <QRCode size={200} iconSize={30} value={order?.qr_code_text} icon={DotLogo} errorLevel='H' style={{width: '100%', height: 'unsed', maxWidth: '200px', margin: 'auto'}} />
                    <div style={{padding: '5px 20px'}}>
                      <p style={{border: '1px solid gray', overflowWrap: 'break-word'}}>
                        {order.qr_code_text}
                      </p>
                    </div>
                  </Col>
                </>
              }
          </Row>
        </Col>
      </Row>
    </div>
  );
}