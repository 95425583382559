import React, { useState, useEffect } from 'react';
import { Button, Input, Flex, Space } from 'antd';
import { lpad } from '../single-page/utils/index.js';
import TicketsIcon from '../images/tickets-purple.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { numberAsCurrency } from '../single-page/utils/index.js';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../single-page/App.js';
import { CampaignContext } from '../single-page/App.js';
import BlueTicketsIcon from '../images/tickets-blue.svg';
import InstantPrizesSummary from './InstantPrizesSummary.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import Cookie from 'js-cookie';
import './CampaignFastBuyControls.css';

const inputStyle = {
  background: '#E8ECFD',
  border: '1px solid #E8ECFD',
  textAlign: 'center',
  // lineHeight: 1.5714285714285714,
  height: '80px',
  padding: '9px 15px',
  borderRadius: '10px',
  color: '#2F058B',
};

const buttonStyle = {
  background: '#253875',
  border: '1px solid #253875',
  color: 'white',
};

const buyButtonStyle = {
  width: '80%',
  textAlign: 'left',
  margin: '0 auto',
  display: 'block',
  backgroundColor: '#f7eb19',
  color: '#253875',
  fontWeight: 'bold',
  border: '0px none transparent',
}

const smallButtonStyle = {
  height: '42px',
  width: '42px',
}

export default function CampaignFastBuyControls({ campaign, buyCall, loading, project }) {
  if (!campaign) return null;

  const start_time = moment(campaign.start_time);
  if (!campaign['open?']) {
    if (start_time.isAfter(moment())) {
      return (
        <div className="center">
          <h1>
            As vendas desta campanha começam às {start_time.format('HH:mm')} do dia {start_time.format('DD/MM/YYYY')}.
          </h1>
          <p>
            Fique de olho nas nossas redes sociais para as novidades!
          </p>
        </div>
      );
    } else {
      return (
        <div className="center">
          <h1>
            As vendas desta campanha já foram encerradas.
          </h1>
          <p>
            Fique de olho nas nossas redes sociais para não perder a próxima campanha!
          </p>
        </div>
      );
    }
  }
  const [quantity, setQuantity] = useState(campaign?.default_ticket_selection || 1);
  const [inputQuantity, setInputQuantity] = useState(quantity);
  const [firstClick, setFirstClick] = useState(false);
  const upRef = React.createRef();
  const downRef = React.createRef();
  const SOUND_ENABLED = false;
  
  function numberButtonCallback(number) {
    if (firstClick) {
      safeSetQuantity(parseInt(number.replace('*', '')))
    } else {
      safeSetQuantity(quantity + parseInt(number.replace('*', '')))
    }
  }

  function changeInputQuantity(number) {
    setInputQuantity(number);
    safeSetQuantity(number, false, false)
  }

  function playSound(old_quantity, new_quantity) {
    if (!SOUND_ENABLED) return;
    if (new_quantity > old_quantity) {
      if (upRef.current) {
        
        upRef.current.currentTime = 0;
        upRef.current.play();
      }
    } else if (new_quantity < old_quantity) {
      if (downRef.current) {
        downRef.current.currentTime = 0;
        downRef.current.play();
      }
    }
  }

  function setQuantities(number) {
    setQuantity(number);
    setInputQuantity(number);
  }

  function safeSetQuantity(number, allowBlank = false, overwriteValue = true) {
    if (isNaN(number) || number < campaign?.min_amount) {
      if (overwriteValue) {
        if (allowBlank) {
          setQuantities('');
        } else {
          setQuantities(campaign?.min_amount);
        }
      }
    } else if (number > campaign?.max_amount) {
      if (overwriteValue)
        setQuantities(campaign?.max_amount);
    } else if (number < 1) {
      if (overwriteValue)
        setQuantities(1);
    } else {
      playSound(quantity, number);
      setQuantities(number);
    }
    setFirstClick(false);
  }

  function buyTickets() {
    if (quantity == 0)
      return;

    buyTicketsCall();
  }

  async function tryToIncreaseQuantity() {
    let new_quantity = quantity;
    if (campaign?.max_amount && new_quantity >= campaign?.max_amount) {
      return quantity ;
    }
    // Disable the dialog
    return quantity;

    if (new_quantity == 1)
      new_quantity = 3;
    if (new_quantity < 4)
      new_quantity = 5;
    else
      new_quantity = Math.ceil((new_quantity + 1) / 10) * 10;
    
    // Disabling new quantity logic for now
    return quantity;
    const response = await Swal.fire({
      title: 'Aumente suas chances de ganhar!',
      html: 
        `<p style="font-size: 110%">Você escolheu ${quantity} títulos por ${numberAsCurrency(campaign.ticket_price * quantity)}.\
         <br/>Que tal levar ${new_quantity} títulos por apenas ${numberAsCurrency(campaign.ticket_price * new_quantity)}?<p>`,
      showCancelButton: true,
      confirmButtonText: 'Sim, quero!',
      imageUrl: BlueTicketsIcon,
      imageWidth: 200,
      imageHeight: 100,
      confirmButtonColor: '#253875',
      cancelButtonText: `Não, adicionar apenas ${quantity}`,
      reverseButtons: true,
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    if (response.isConfirmed) {
      return new_quantity;
    } else {
      return quantity;
    }
  }

  async function buyTicketsCall() {
    let new_quantity = await tryToIncreaseQuantity();
    
    buyCall(new_quantity);
  }

  return (
    <div className="fast_buy" style={{padding: '20px 0px'}}>
      <div className="smaller_container">
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <h1 style={{margin: 0, fontSize: '22px'}}>
            Títulos
          </h1>
          <div style={{maxWidth: '460px', margin: `0 auto`}}>
            <Flex justify="center" align="center" wrap="wrap" gap="large" className="buyButtons">
              <Button onClick={() => safeSetQuantity(quantity - campaign.down_step)} shape="rounded" style={{ ...buttonStyle, fontWeight: '500', height: 'unset', minWidth: '98px', minHeight: 80, fontSize: 40}} >-</Button>
              <div style={{...inputStyle, padding: 0}}>
                {/* {quantity} */}
                <input type="tel" className="numberInput" value={inputQuantity} onBlur={() => safeSetQuantity(parseInt(event.target.value))} onChange={() => changeInputQuantity(event.target.value)}
                       style={{border: 'none', background: 'transparent', fontSize: 32, marginTop: '18px', width: 0, minWidth: '96px', textAlign: 'center', color: 'rgb(47, 5, 139)', fontWeight: '500'}} />
              </div>
              <Button onClick={() => safeSetQuantity(quantity + campaign.up_step)} shape="rounded" style={{ ...buttonStyle, fontWeight: '500', height: 'unset', minWidth: '98px', minHeight: 80, fontSize: 40}} >+</Button>
            </Flex>
            <h1 style={{margin: '16px 0 10px 0', fontSize: '22px'}}>
              Multiplique sua sorte
            </h1>
            <Flex justify="center" align="center" wrap="wrap" gap="large" className="buyButtons">
              {
                campaign.buttons_array.map(button => (
                  <Button onClick={() => numberButtonCallback(button)} key={Math.random()} type="primary" shape="rounded" 
                          style={{ ...buttonStyle, height: 'unset', minWidth: '98px', minHeight: 80}} className={button.includes('*') ? 'special_button' : ''}>
                    <span style={{fontSize: 30}} className="number">{firstClick || '+'}{lpad(button.replace('*', ''), 2)}</span>
                  </Button>
                ))
              }
            </Flex>
            <br/><br/>
            <Button size="large" onClick={buyTickets} loading={loading} type="default" shape="round" style={buyButtonStyle} icon={<img src={TicketsIcon} style={{height: '20px'}} />} >
              <span style={{float: 'right', marginTop: '-3px', lineHeight: '100%', textAlign: 'center'}}>
                {numberAsCurrency(campaign.ticket_price * quantity)}
                <br/>
                <small style={{fontWeight: '400', fontSize: '70%'}}>({quantity} título{quantity > 1 && 's'})</small>
              </span>
              <span style={{position: 'relative', top: '-4px', fontSize: '20px'}}>Comprar</span>
            </Button>
            {/* Código removido, pois não podemos selecionar cartelas sem saber o número */}
            {
              campaign?.bingo && project &&
                <Link to={`/${project.name}/site/campanhas/${campaign.id}/escolha`}>
                  <Button type="link" style={{width: '100%'}}>
                    Ou clique aqui para escolher suas cartelas
                  </Button>
                </Link>
            }
          </div>
          <InstantPrizesSummary campaign={campaign} />
          {
            campaign?.description_body &&
              <div className="left rounded_square" style={{backgroundColor: '#E8ECFD', maxHeight: 190, overflowY: 'auto'}}>
                <div dangerouslySetInnerHTML={{__html: campaign.description_body}} style={{maxHeight: 130, overflowY: 'auto', margin: 10}} />
              </div>
          }
        </Space>
        {
          SOUND_ENABLED &&
            <>
              <audio ref={upRef} src="/sounds/up.mp3" preload="auto"></audio>
              <audio ref={downRef} src="/sounds/down.mp3" preload="auto"></audio>     
            </>
        }
      </div>
    </div>
  );
}