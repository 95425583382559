import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Spin } from 'antd';
import TrophyIcon from '../../images/trophy.svg';
import { Row, Col } from 'antd';
import OrderStatus from './order/OrderStatus';
import OrderSummary from './order/OrderSummary';
import OrderQrCode from './order/OrderQrCode';
import OrderTickets from './order/OrderTickets';
import Lottie from "lottie-react";
import confettiAnimation from "../../images/confetti.json";
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';
import { numberAsCurrency } from '../utils';
import OrderAd from './order/OrderAd';

export default function OrderDetails({isMobile, project, orderIdParam, hideSteps = false}) {
  let { orderId } = useParams();
  if (orderIdParam) {
    orderId = orderIdParam;
  }
  let { secret } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const unpaidOrder = useRef(false);
  const qrcodeEventTracked = useRef(false);
  const timeoutRef = useRef(null);
  const [fetchOrderDelay, setFetchOrderDelay] = useState(1000);
  const orderStep = order?.status != 'completed' ? 2 : 3;
  const [instantPrize, setInstantPrize] = useState(false);
  const [instantPrizes, setInstantPrizes] = useState({});

  useEffect(() => {
    fetchOrder();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, []);

  function checkInstantPrize(order) {
    let prizes = [];
    order.tickets.forEach(ticket => {
      if (ticket.winners.length > 0 && ticket.winners.find(winner => winner.prize_type == 'instant')) {
        ticket.winners.filter(winner => winner.prize_type == 'instant' && winner.show_winners_instantly).forEach(winner => {
          prizes.push(winner);
        })
      }
    });
    let prizesGroupedByValue = prizes.reduce((acc, prize) => {
      if (!acc[prize.prize_value]) {
        acc[prize.prize_value] = [];
      }
      acc[prize.prize_value].push(prize);
      return acc;
    }, {});
    setInstantPrizes(prizesGroupedByValue);
  }

  function orderUrl() {
    if (secret) {
      return `/api/ext/v1/purchase_orders/${secret}/${orderId}.json`;
    } else {
      return `/api/int/v1/purchase_orders/${orderId}.json`
    }
  }

  function fetchOrder() {
    axios.get(orderUrl())
      .then((response) => {
        setOrder(response.data);
        if (response.data.status != 'completed') {
          unpaidOrder.current = true;
        }
        if (response.data.status == 'created') {
          console.log('created, scheduling new fetch');
          timeoutRef.current = setTimeout(() => {
            fetchOrder();
          }, fetchOrderDelay);
          setFetchOrderDelay(fetchOrderDelay * 2);
        } else if (response.data.status == 'waiting_payment') {
          if (!qrcodeEventTracked.current) {
            qrcodeEventTracked.current = true;
          }
          console.log('waiting payment, scheduling new fetch');
          timeoutRef.current = setTimeout(() => {
            fetchOrder();
          }, 12000);
        } else if (response.data.status == 'paid') {
          console.log('paid, scheduling new fetch');
          timeoutRef.current = setTimeout(() => {
            fetchOrder();
          }, 20000);
        } else if (response.data.status == 'completed') {
          console.log('completed, finished');
          checkInstantPrize(response.data);
          if (unpaidOrder.current) {
            // Add a parameter in the URL to indicate that the order was completed, so GTM can track it
            console.log('adding order_completed=true to URL');
            window.history.replaceState({}, '', window.location.href + '?order_completed=true');
            unpaidOrder.current = false;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setOrder({});
        toast.error('Erro ao buscar pedido');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="container" style={{position: 'relative'}}>
        {
          !hideSteps &&
            <PurchaseOrderSteps step={orderStep} isMobile={isMobile} />
        }
        <h1>Pedido {order?.id}</h1>
        {
          Object.keys(instantPrizes).length !== 0 &&
          <div className="alert alert-warning">
            <h2 style={{textAlign: 'center'}}>
              Parabéns! Você ganhou <span style={{color: '#0EC920'}}>prêmio(s) instantâneo(s)!</span>
            </h2>
            <br/>
            <div id="orderInstantPrizesContainer">
              <div>
                <img src={TrophyIcon} style={{width: '140px'}} />
              </div>
              {
                Object.keys(instantPrizes).map(prizeValue => (
                  <div key={prizeValue} style={{fontSize: 18}}>
                    <div style={{fontSize: 20, marginBottom: 15}}>Prêmio(s) de <b>{numberAsCurrency(prizeValue)}</b></div>
                    <div className="prize_list">
                      {instantPrizes[prizeValue].map(prize => (
                        <span className="number">{prize.ticket_number}</span>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <Lottie style={{position: 'absolute', top: '0px', left: '0px', right: '0px'}} animationData={confettiAnimation} />
          </div>

        }
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {
              loading ?
                <Spin size="large" fullscreen />
                :
                order.status == 'waiting_payment' ? 
                  <OrderQrCode order={order} isMobile={isMobile} />
                  :
                  <OrderStatus order={order} />
            }
          </Col>
          {
            order.status != 'waiting_payment' &&
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <OrderSummary order={order} />
              </Col>
          }
          {
            order && order.status == 'completed' &&
            <Col span={24}>
              <OrderAd order={order} />
            </Col>
          }
          {
            order.status == 'completed' &&
            <Col span={24}>
              <OrderTickets order={order} />
            </Col>
          }
        </Row>
      </div>
    </>
  );
}