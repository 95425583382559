import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Input, Button, Form, Layout } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { UserContext } from '../App.js';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';

const { Content } = Layout;

export default function SignIn({isMobile, project}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const acesso_url = window.location.pathname.startsWith('/site/acesso');

  function sign_in(formData) {
    document.activeElement.blur();
    setLoading(true);
    axios.post('/customers/sign_in', { customer: formData })
      .then(function(){
        window.location.href = `/${project.name}/site`
      })
      .catch(function(error){
        if (error.response.status == 401){
          setErrors({password: ['Senha inválida']});
        } else {
          toast.error('Erro ao processar solicitação.');
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!user || !user.cpf) {
      history.push(`/${project.name}/site/cpf`);
    } else {
      form.setFieldsValue({ cpf: user.cpf, name: user.name });
    }
  }, []);

  return (
    <>
      <PurchaseOrderSteps step={1} isMobile={isMobile} />
      <Content className="smallest_container">
        <p className="center">
          Boas vindas à Nacional Cap!
        </p>
        <h1 style={{fontSize: '150%'}}>
          Precisamos identificá-lo para prosseguirmos.
        </h1>
        <Form form={form} layout="vertical" name="sign_in" className="login-form" initialValues={{ remember: true }} onFinish={sign_in}>
          <Form.Item name="cpf" rules={[{ required: true }]} label="CPF" required hasFeedback={!!errors['cpf']} validateStatus={errors['cpf'] && 'error'} help={errors['cpf']?.join(', ')}>
            <MaskedInput name="phone" form={form}  disabled={true} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]} required label="Senha" hasFeedback={!!errors['password']} validateStatus={errors['password'] && 'error'} help={errors['password']?.join(', ')}>
            <Input.Password autoFocus prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
          </Form.Item>
          <Form.Item>
            <Button shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
}