import React from 'react';
import { Space, Row, Col } from 'antd';

export default function TermsEn() {

  return (
    <div className="smaller_container" style={{padding: '50px 35px'}}>
      <Space direction="vertical">
        <h1>Nacional Cap Use Terms</h1>
        <div>
          <div><strong>1. General Provisions</strong><br/><br/> This document establishes the terms of use for the acquisition and
            use of the prize philanthropy capitalization bond, offered by KOVR in partnership with the operator NACIONAL CAP. By
            purchasing this bond, you agree to the conditions described herein.</div>
          <div>&nbsp;</div>
          <div><strong>2. Bond Value</strong><br/><br/> The nominal value of the capitalization bond varies according to the
            current edition. By purchasing the bond, you acknowledge and agree to this value.&nbsp;</div>
          <div>&nbsp;</div>
          <div><strong>3. Sale via PIX and Fully Online</strong><br/><br/> The sale of this capitalization bond occurs exclusively
            through the PIX system and entirely online. To purchase the bond, you will need to use PIX as the payment method and
            follow the instructions provided on the website.&nbsp;</div>
          <div>&nbsp;</div>
          <div><strong>4. Holder's Responsibility</strong><br/><br/> By purchasing the capitalization bond, you commit to
            providing accurate and complete information during the purchase process. It is your responsibility to ensure that
            the contact information provided is correct to receive communications related to the bond and potential prizes.
          </div>
          <div><strong>&nbsp;</strong></div>
          <div><strong>5. Prizes</strong><br/><br/>The prize philanthropy capitalization bond offers participants the chance to
            win prizes according to the rules established by KOVR. The prizes will be announced through NACIONAL CAP's official
            channels and authorized resellers and communicated directly to bond holders.<strong><br/></strong><br/><strong>6. Bond
              Redemption</strong><br/><br/>The right to redeem this bond will be entirely allocated to the charitable social
            assistance entity Casa Ronald McDonald - Association for Supporting Children with Cancer in Rio de Janeiro,
            certified under current legislation.<br/>&nbsp;</div>
          <div><strong>7. Image Rights</strong><br/><br/>As a winner, the participant authorizes, free of charge, the use of their
            name, image, voice, and personal characteristics for promoting the "NACIONAL CAP" campaign and its future
            advertising campaigns in any media and territory, without time limitations. The participant waives any rights to
            royalties, commissions, or future remuneration, recognizing that, after granting the authorization, they will not be
            entitled to additional compensation, whether for the use of their image or any other form of remuneration.<br/>&nbsp;
          </div>
          <div><strong>8. Changes to the Terms of Use</strong><br/><br/>NACIONAL CAP reserves the right to modify these terms of
            use at any time, with prior notice to bond holders. It is the holder's responsibility to monitor any updates to the
            terms.</div>
          <div><strong>&nbsp;</strong></div>
          <div><strong>9. Contact</strong><br/><br/>For any questions or support requests related to the capitalization bond,
            contact NACIONAL CAP's customer service through the channels provided on the official website.&nbsp;</div>
          <div>&nbsp;</div>
          <div><strong>10. Contracting</strong><br/><br/>The acquisition of the Capitalization Bond implies automatic adherence to
            all terms of the General Conditions, available for consultation on the SUSEP website, as well as the Privacy Policy
            and Participation Conditions of the Capitalization Bond found on the NACIONAL CAP campaign website;</div>
          <div><br/><strong>&nbsp;By purchasing the capitalization bond, you indicate your agreement with all terms and
              conditions set forth in this document.</strong></div>
          <div><br/>&nbsp;</div>
        </div>
      </Space>
    </div>
  )
}